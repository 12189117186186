import React from 'react'
import ReactMarkdown from 'react-markdown'
import styled from 'styled-components'

const StyledWrapper = styled.div`
.header-text{
    font-family: ${props => props.theme["primaryFont"]} !important;
}

.header-text strong{
    font-size:32px;
    font-family: ${props => props.theme["primaryFont"]} !important;
    font-weight:900;
}
`


const JudgingSummary = ({data}) => {
    return (
        <StyledWrapper>
            <div className="col-lg-8 col-12 py-5 header-text">
                <ReactMarkdown source={data} />
            </div>
        </StyledWrapper>
    )
}

export default JudgingSummary;
import React, { useState, useEffect } from "react";
import axios from "axios";
import Layout from "../components/layout";
import styled from "styled-components";
import SEO from "../components/seo";
import JudgingSummary from "../components/Judging/JudgingSummary";
import Judges from "../components/Judging/Judges";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import ReactGA from "react-ga";
import { QueryClient, QueryClientProvider, useQuery } from "react-query";
import Loader from "../components/Reusables/Loader";
const StyledWrapper = styled.div`
  .margin-bot {
    margin-bottom: 100px;
  }

  .bottom-div {
    color: ${props => props.theme["borderBottomColor"]};
  }

  .end-div {
    font-size: 48px;
    color: ${props => props.theme["borderBottomColor"]};
    text-decoration: underline;
    font-weight: 800;
    @media (max-width: 600px) {
      font-size: 28px;
    }
  }
`;

const body = (
  <div>
    <p>
      The independent judging panel for the 2021 Student Social Mobility Awards
      will be announced in the coming months. It will comprise ten leading
      representatives from the fields of politics, academia, education and
      business, with significant youth representation.
    </p>
    <p>
      We will follow a thorough judging process, using best practices to
      eliminate bias, such as anonymising nominations (name blind) and where
      appropriate removing names of employers and other prominent organisations
      during the initial shortlisting and final judging process.
    </p>
    <p>
      The social mobility charity, upReach, anonymously review all nominations
      and produced a detailed Judging Pack for the Judging Panel. The judges
      will receive full details of each shortlisted nominee including detailed
      contextual data, personal statements and a supporting statement from the
      nominator or referee.
    </p>
    <p>
      The winners of most Awards will be selected by the Judging Panel. The
      exceptions are those that are determined by the student vote, namely the
      winner of the Employer Initiative, University Initiative, Outstanding
      Achievement and Biggest Champion of Social Mobility Awards. We are very
      grateful to all the judges for their support.
    </p>
    <p>
      The judging decisions are final and no correspondence will be entered
      into.
    </p>
  </div>
);

const data = {
  title: "Judge Selection Process",
  description: body,
};

const queryClient = new QueryClient();

export default function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <JudgingPanel />
    </QueryClientProvider>
  );
}
const fetchData = () => {
  return axios.get(`${process.env.GATSBY_API_URL}/judgingpage`);
};

function JudgingPanel() {
  const [newData, setNewData] = useState(null);
  const { data: pageData } = useQuery("judgingPanel", fetchData);

  useEffect(() => {
    if (pageData !== undefined) {
      setNewData(pageData.data);
    }
  }, [pageData]);
  const handleClick = () => {
    ReactGA.event({
      category: "Judging Panel Page",
      action: "Get in Touch Button Click",
    });
  };
  return (
    <Layout>
      <StyledWrapper>
        {newData === null && <Loader />}
        {newData && <SEO title={newData.SEOtitle} />}

        <div className="container-fluid padding-left-8 padding-right-8 white-background py-2">
          {newData && newData.showSummary && (
            <JudgingSummary data={newData.Summary} />
          )}
          {newData && newData.ShowJudges && (
            <div className="margin-bot container-fluid">
              {newData.Judges.length > 0 && <Judges data={newData.Judges} />}
            </div>
          )}
        </div>
        {newData && newData.CtaButton && (
          <div
            className="container-fluid bottom-div mt-0"
            style={{ background: newData.CtaButton.bgColor }}
          >
            <div className="col-12 py-4 d-flex justify-content-center">
              <AnchorLink
                to={newData.CtaButton.link}
                className="end-div"
                onAnchorLinkClick={handleClick}
              >
                {newData.CtaButton.title}
              </AnchorLink>
            </div>
          </div>
        )}
      </StyledWrapper>
    </Layout>
  );
}

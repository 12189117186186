import React from "react";
import styled from "styled-components";
import image1 from "../../images/pageImages/advisory/Andrew.jpg";

const StyledWrapper = styled.div`
.image {
    display: block;
    width: ${props => props.theme["percent-100"]};
    height: auto;
  }
  .overlay {
    position: absolute;
    top: ${props => props.theme["zero"]};
    bottom: ${props => props.theme["zero"]};
    left: 0px;
    right: ${props => props.theme["zero"]};
    height: ${props => props.theme["percent-100"]};
    width: 100%;
    opacity: ${props => props.theme["zero"]};
    transition: 0.2s ease;
    background-color: ${props => props.theme["borderBottomColor"]};
  }
  .container:hover .overlay {
    opacity: 1;
  }
  .text {
    color: ${props => props.theme["primaryColor"]};
    font-size: ${props => props.theme["externalLinkSize"]};
    position: absolute;
    top: ${props => props.theme["percent-50"]};
    left: ${props => props.theme["percent-50"]};
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    text-align: center;
    width: 95%;
  }
  }

  .upreach-employee-img {
    object-position:top;
    object-fit: cover;
    width: 100%;
    height: 400px;
    @media(min-width:1025px) and (max-width:1440px){
      max-height: 350px;
    }

    @media(min-width:1441px){
      max-height: 500px;
    }

    @media(max-width:600px){
      max-height:300px;
    }
  }

  .team-names,
  .team-title {
    font-weight: bold;
    font-size:18px;
  }

  .team-description {
    font-size: 14px;
  }

  .padding-sides{
    padding-left:0px;
    padding-right:0px;
    padding-bottom:1px;
  }

  .header-text{
    font-family: ${props => props.theme["primaryFont"]} !important;
    font-size:32px;
  }

  .container {
    position: relative;
    width: ${props => props.theme["percent-100"]};
  }
`;

const TeamInfo = [
  {
    data: [
      {
        name: "Andy Ratcliffe",
        designation: "Judge",
        image: `${image1}`,
        description:
          "Deborah was part of the founding team at upReach, where she worked for almost four years in a number of different roles including leading the first Associate programmes. She is currently a diversity recruiter at McKinsey & Company.",
      },
      {
        name: "Andy Ratcliffe",
        designation: "Judge",
        image: `${image1}`,
        description:
          "Deborah was part of the founding team at upReach, where she worked for almost four years in a number of different roles including leading the first Associate programmes. She is currently a diversity recruiter at McKinsey & Company.",
      },
      {
        name: "Andy Ratcliffe",
        designation: "Judge",
        image: `${image1}`,
        description:
          "Deborah was part of the founding team at upReach, where she worked for almost four years in a number of different roles including leading the first Associate programmes. She is currently a diversity recruiter at McKinsey & Company.",
      },
      {
        name: "Andy Ratcliffe",
        designation: "Judge",
        image: `${image1}`,
        description:
          "Deborah was part of the founding team at upReach, where she worked for almost four years in a number of different roles including leading the first Associate programmes. She is currently a diversity recruiter at McKinsey & Company.",
      },
    ],
  },
];

export default function Judges({ data }) {
  const mainUrl = process.env.GATSBY_API_URL;
  return (
    <StyledWrapper className="mt-2">
      <h1 className="header-text">Judges</h1>
      <div className="row">
        {data.length > 0 &&
          data.map(item => (
            <div className="col-12 col-md-6 col-lg-4 mt-4">
              <div className="container padding-sides">
                <div>
                  {item.Image && (
                    <img
                      className="img-fluid upreach-employee-img mx-auto d-block"
                      src={mainUrl + item.Image.url}
                      alt=""
                    />
                  )}

                  <h5 className="mb-0 team-names mt-3 text-center">
                    {item.name}
                  </h5>
                  <h5 className="mb-0 team-names mt-1 text-center">
                    {item.role}
                  </h5>
                </div>

                <div className="overlay">
                  <div className="text px-2">
                    <p className="mb-0 team-names">{item.name}</p>
                    <p className="mb-0 team-description">{item.description}</p>
                  </div>
                </div>
              </div>
            </div>
          ))}
      </div>
    </StyledWrapper>
  );
}
